.ol-map {
  height: 100vh;
  width: "100%";
}

.ol-full-screen {
  top: .5em;
  right: .5em;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item-active {
  background-color: transparent !important;
}